<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">Votaciones</h2>

          <b-list-group>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/votos-coalicion"
            >
              Votos en Coalición
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/casillas-electorales"
            >
              Casillas Electorales
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/participacion-ciudadana"
              active
            >
              Participación Ciudadana
            </b-list-group-item>
            <b-list-group-item
              to="/centro-de-ayuda/votaciones/lista-nominal"
            >
              Lista Nominal
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Votaciones
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Participación Ciudadana
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Participación Ciudadana
          </h2>

          <div class="row align-items-center">
            <div class="col-12 mb-3 col-lg-6">
              <h4>1.</h4>

              <p>
                Es un indicador que se refiere al total de la ciudadanía
                que votó con base en la Lista Nominal de las Actas contabilizadas.
                <br />
                <br />
                La Lista Nominal se refiere a la relación de la ciudadanía
                inscrita en el Padrón Electoral con credencial vigente
                para ejercer su derecho de votar.
              </p>
            </div>
            <div class="col-12 mb-3 col-lg-6">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/ciudadania-1.svg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>

          <h4 class="text-center color-institucional">
            ¿Cómo se obtiene el porcentaje de Participación Ciudadana?
          </h4>
          <p class="text-center">
            Ejemplo de una casilla:
          </p>

          <img src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/ciudadania-2.svg" alt="" class="img-fluid mx-auto d-block mb-3">

          <p class="font-weight-bold">
            <sup>*</sup> Artículo 253, Numeral 3 de la LGIPE.
          </p>

          <p>
            En toda sección electoral por cada 750 electores o fracción se
            instalará una casilla para recibir la votación de los ciudadanos
            residentes en la misma; de ser dos o más se colocarán en forma
            contigua y se dividirá la Lista Nominal de electores en orden alfabético.
          </p>

          <h4 class="text-center color-institucional">
            Criterios para el cálculo de la Participación Ciudadana
          </h4>

          <div class="row align-items-center">
            <div class="col-12 mb-3 col-lg-6">
              <h4>2.</h4>

              <p>
                <b>
                  Durante la operación
                </b>
              </p>

              <p>
                Hasta el penúltimo corte de información,
                <b>
                  no se incluye la votación de las Casillas Especiales.
                </b>
              </p>

              <ul>
                <li>
                  Si se aplica en esta fase, podría afectar el porcentaje,
                  ya que este tipo de Casillas no cuenta con Lista Nominal.
                </li>
              </ul>
            </div>
            <div class="col-12 mb-3 col-lg-6">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/ciudadania-3.svg" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-12 mb-3 col-lg-6">
              <img src="https://ayuda.ine.mx/2021/informate/assets/img/votaciones/ciudadania-4.svg" alt="" class="img-fluid mx-auto d-block">
            </div>
            <div class="col-12 mb-3 col-lg-6">
              <h4>Último corte</h4>

              <p>
                En éste, las Actas de las
                <b>Casillas Especiales</b>
                se agregan al cálculo del porcentaje.
              </p>
            </div>
          </div>

          <p class="text-center mb-4">
            <b>
              Referencias:
            </b>
            <br />
            · Numeral 26, fracción XII, Anexo 13 del Reglamento de Elecciones. <br />
            · Proceso Electoral Federal 2020 - 2021.
          </p>

          <a
            href="https://ayuda.ine.mx/2021/informate/assets/docs/Votaciones/Participacion_Ciudadana.pdf"
            target="_blank"
            class="btn btn-primary mx-auto d-block"
          >
            Descarga Infografía
          </a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
}

.list-group-item {
  z-index: 0;
}

.grid {
  display: grid;
  align-items: center;
  gap: 45px;
  grid-template-columns: 50px 1fr;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }
}
</style>
